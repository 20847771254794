<template>
  <div class="aboutA">
    <div class="toplogo">
      <img src="../../assets/newlogo.png" alt="" />
    </div>
    <div class="login">
      <div class="con">
        <div class="nei">
          <div class="username">
            <el-input
              placeholder="请输入用户名"
              v-model="username"
              clearable
              @keyup.enter.native="btn"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-s-custom"
                style="font-size: 23px"
              ></i>
            </el-input>
          </div>
          <div class="userpass">
            <el-input
              placeholder="请输入密码"
              v-model="userpass"
              clearable
              type="password"
              @keyup.enter.native="btn"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-lock"
                style="font-size: 23px"
              ></i>
            </el-input>
          </div>
          <div class="userpass remember">
            <el-checkbox v-model="checked">记住用户名</el-checkbox>
          </div>
          <div class="sure">
            <el-button type="success" round @click="btn">登录</el-button>
          </div>
          <div class="bottomBox">
            <!-- <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022010788号</a> -->
            <span @click="beian">鲁ICP备2021041341号-1</span>
           </div>
        </div>
      </div>
      
    </div>
    <div class="botji">
      <img src="../../assets/jiqiren.png" alt="" />
    </div>
   
  </div>
</template>

<script>
import { login } from "../../api/login/index";
export default {
  components: {},
  data() {
    return {
      username: "",
      userpass: "",
      checked: true,
      msg: "",
    };
  },
  created() {
    this.getCookie();
  },
  mounted() {},
  methods: {
    btn() {
      console.log("12345");
      login(this.username, this.userpass).then((res) => {
        // sessionStorage.setItem("username", this.username);
        // sessionStorage.setItem("password", this.userpass);
        // sessionStorage.setItem("token", res.token);
        console.log(res);
        this.$store.state.role = res.role
        // localStorage.setItem('type', res.role )
        if (res.msg == "登录成功！") {
          this.$message.success(res.msg)
          this.$store.commit("saveToken", res.token);
          this.$store.commit("savepassword", this.userpass);
          // 当设置记住用户名时
          if (this.checked == true) {
            this.setCookie(this.username, "", 7);
          } else {
            this.clearCookie();
          }
          if (res.role == 1) {
            this.$router.push("/bigshow");
          }
          if (res.role == 0 && res.mag == "请修改密码！") {
            this.$router.push("/systemuse");
          } else if (res.role == 0 && res.mag != "请修改密码！") {
            this.$router.push("/equipuse");
          }
        } else if(res.msg == "登录成功,请修改密码！"){
           this.$message.success(res.msg)
          // 新用户 第一次登录 提示修改密码 再次调用登录接口
            login(this.username, this.userpass).then((res) => {
        // sessionStorage.setItem("username", this.username);
        // sessionStorage.setItem("password", this.userpass);
        // sessionStorage.setItem("token", res.token);
        //console.log(res);
        //this.$store.state.role = res.role
         if (res.msg == "登录成功！") {
          this.$store.commit("saveToken", res.token);
          this.$store.commit("savepassword", this.userpass);
          if (this.checked == true) {
            this.setCookie(this.username, "", 7);
          } else {
            this.clearCookie();
          }
          if (res.role == 1) {
            this.$router.push("/bigshow");
          }
          if (res.role == 0 && res.mag == "请修改密码！") {
            this.$router.push("/systemuse");
          } else if (res.role == 0 && res.mag != "请修改密码！") {
            this.$router.push("/equipuse");
          }
         }
        })
        }
        else {
          this.$message.error(res.msg);
        }
      });
    },
    // 设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    //读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //再次切割
          //判断查找相对应的值
          if (arr2[0] == "userName") {
            this.username = arr2[1]; //保存到保存数据的地方
          }
        }
      }
    },
    //清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    },
    beian() {
      // window.location.href = 'https://beian.miit.gov.cn/'
      window.open('https://beian.miit.gov.cn/', '_blank')
    }
  },
};
</script>

<style  lang='less' scoped>
.aboutA {
  // position: fixed;
  width: 100%;
  height: 100%;
  min-width: 900px;
  min-height: 700px;
  // background-color: #cecdcd;
  .con {
    width: 1100px;
    height: 866.1px;
    // background: gray;
    // background: #e6e6e6;\
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background-image: url("../../assets/niu.png");
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 90% 90%;
  }

  .username,
  .userpass,
  .sure {
    width: 50%;
    margin: 0 auto;
    margin-top: 120px;
    font-size: 40px;
  }
  .userpass {
    margin-top: 5px;
  }
  .bottomBox{
    width:55%;
    margin: 0 auto;
    margin-top: 290px;
    font-size: 20px;
    // background-color: #567822;
  }
  /deep/ .el-input__inner {
    padding-left: 45px !important;
    background: transparent !important;
    color: white !important;
    border: none;
    border-bottom: 2px solid #797979 !important;
    border-radius: 0;
    font-size: 28px;
  }
  /deep/.el-input {
    margin-top: 30px;
  }
  .nei {
    width: 550px;
    // margin: 0 auto;
    // margin-top: 270px;
    position: absolute;
    top: 160px;
    left: 270px;
  }
  .sure {
    margin-top: 5px;
    button {
      width: 100%;
      font-size: 24px;
      margin-top: 20px;
    }
  }
  .remember {
    display: flex;
    justify-content: flex-end;
    height: 20px;
    margin-top: 10px;
    /deep/.el-checkbox__label {
      color: white;
      font-size: 18px;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background: #567822;
      border-color: #567822;
    }
  }
  /deep/ .el-button--primary {
    width: 100%;
    height: 44px;
    font-size: 20px;
    color: #fff;
    background-color: #4483f4;
    border-color: white;
    margin: 0 auto;
  }

  .toplogo {
    margin: 20px;
    img {
      width: 210px;
      height: 70px;
    }
  }
  .botji {
    position: fixed;
    bottom: 30px;
    right: 20px;
    img {
      width: 39px;
      height: 53px;
    }
  }
  /deep/.el-button--success {
    background: #567822;
    border: none;
  }
}
</style>